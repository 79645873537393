import { Fragment } from 'react'
import { Link, Route, useLocation } from 'react-router-dom'

import Guardian from '../../router/Guardian'

const defaultGuardianConfig = {
  protect: false,
  customValidation: () => false,
  concatValidations: false,
  redirectOnSession: false,
  pathToRedirect: '/',
  escapeTo: '/',
}

export const generateLinks = (routes, formatMessage) =>
  routes &&
  routes
    .filter((r) => !r.hidden)
    .map((route) => {
      const item = {
        title: formatMessage({ id: route.name }),
        key: route.path,
        icon: route.icon,
        label: route.path ? (
          <Link to={route.path}>{formatMessage({ id: route.name })}</Link>
        ) : (
          formatMessage({ id: route.name })
        ),
        children:
          route.children && generateLinks(route.children, formatMessage),
      }

      return item
    })

export const generateRoutes = (routes) =>
  routes &&
  routes.map(
    (
      { path, layout: Layout, component: Component, guardianConfig, children },
      key
    ) => {
      const guardianProps = { ...defaultGuardianConfig, ...guardianConfig }
      const validRoute = Component && path

      return (
        <Fragment key={key}>
          {validRoute && (
            <Route
              key={path}
              path={path}
              element={
                <Guardian {...guardianProps}>
                  {Layout ? <Layout>{<Component />} </Layout> : <Component />}
                </Guardian>
              }
            />
          )}
          {children && generateRoutes(children)}
        </Fragment>
      )
    }
  )

export const useActivePaths = () => [useLocation().pathname]
