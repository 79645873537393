import { Card } from 'antd'
import { useEffect } from 'react'

const RequestFeatures = () => {
  useEffect(() => {
    if (window && document) {
      const script = document.createElement('script')
      const body = document.getElementsByTagName('body')[0]
      script.src = '//js.hsforms.net/forms/v2.js'
      body.appendChild(script)
      script.addEventListener('load', () => {
        window.hbspt.forms.create({
          region: 'na1',
          portalId: '23750101',
          formId: '51ced883-0476-4a5a-b5aa-06bc792d67c6',
          target: '#hbspt-form',
        })
      })
    }
  }, [])
  return (
    <div className='custom-container view'>
      <Card>
        <div id='hbspt-form' className='p-5'></div>
      </Card>
    </div>
  )
}

export default RequestFeatures
