export const UserRole = {
  SuperUser: 'SuperUser',
  VPRRHH: 'VPRRHH',
  'GERENTE DE RECURSOS HUMANOS': 'GERENTE DE RECURSOS HUMANOS',
  'COORDINADOR DE RECURSOS HUMANOS': 'COORDINADOR DE RECURSOS HUMANOS',
  'GENERALISTA DE RECURSOS HUMANOS': 'GENERALISTA DE RECURSOS HUMANOS',
  'GERENTE DE AREA': 'GERENTE DE AREA',
  FloatingManager: 'FloatingManager',
  'GERENTE DE TIENDA': 'GERENTE DE TIENDA',
  'ASISTENTE PISO DE VENTA SENIOR': 'ASISTENTE PISO DE VENTA SENIOR',
  'ASISTENTE DE PISO DE VENTA': 'ASISTENTE DE PISO DE VENTA',
  'DIRECTOR TECNICO': 'DIRECTOR TECNICO',
  'AUXILIAR DE FARMACIA': 'AUXILIAR DE FARMACIA',
  RegentOfPharmacy: 'RegentOfPharmacy',
  PARQUERO: 'PARQUERO',
  'ASESOR DE OTC': 'ASESOR DE OTC',
  'ASESORA DE BELLEZA': 'ASESORA DE BELLEZA',
  PayrollManager: 'PayrollManager',
  'ASISTENTE PISO DE VENTAS': 'ASISTENTE PISO DE VENTAS',
  //SeniorSalesFloorAssistant: 'SeniorSalesFloorAssistant',
  'APRENDIZ FARMACIA': 'APRENDIZ FARMACIA',
  BeautyAdvisor: 'BeautyAdvisor',
  'SUBGERENTE DE TIENDA': 'SUBGERENTE DE TIENDA',
  'FARMACEUTICO ASISTENCIAL': 'FARMACEUTICO ASISTENCIAL',
  PrescriptionPharmacist: 'PrescriptionPharmacist',
  Regent: 'Regent',
  'ASISTENTE DE FARMACIA 4 Hs': 'ASISTENTE DE FARMACIA 4 Hs',
  InceTrainee: 'InceTrainee',
  Intern: 'Intern',
  OTCAssistant: 'OTCAssistant',
  'ASISTENTE DE PISO DE VENTA 4 Hs': 'ASISTENTE DE PISO DE VENTA 4 Hs',
  'ASISTENTE DE PISO DE VENTA 4 HRS': 'ASISTENTE DE PISO DE VENTA 4 HRS',
  WeekendSalesFloorAssistant: 'WeekendSalesFloorAssistant',
  RegionalManager: 'RegionalManager',
  ITSecurity: 'ITSecurity',
  RecruitmentandSelection: 'RecruitmentandSelection',
  wellness: 'wellness',
  OPTOMETRA: 'OPTOMETRA',
  'ASESORA DERMO': 'ASESORA DERMO',
  AssistantPointSaleDig: 'AssistantPointSaleDig',
  AssistantPointSaleDigSr: 'AssistantPointSaleDigSr',
  apv6Hs: 'apv6Hs',
  lossControlAndServiceAssistant: 'lossControlAndServiceAssistant',
  'GERENTE DE TIENDA A': 'GERENTE DE TIENDA A',
  'GERENTE DE TIENDA B': 'GERENTE DE TIENDA B',
  'GERENTE DE TIENDA C': 'GERENTE DE TIENDA C',
  'ASISTENTE DE PISO DE VENTA FS': 'ASISTENTE DE PISO DE VENTA FS',
  'ASISTENTE PISO DE VENTA NOCTURNO': 'ASISTENTE PISO DE VENTA NOCTURNO',
  'ASIST. PUNTO DE VENTA SENIOR DIG.': 'ASIST. PUNTO DE VENTA SENIOR DIG.',
  'ASIST. PUNTO DE VENTA DIG.': 'ASIST. PUNTO DE VENTA DIG.',
  'AUXILIAR DE FARMACIA NOCTURNO': 'AUXILIAR DE FARMACIA NOCTURNO',
  'AUXILIAR DE FARMACIA 4 HRS': 'AUXILIAR DE FARMACIA 4 HRS',
  'APV TEMPORAL': 'APV TEMPORAL',
  DESARROLLO: 'DESARROLLO',
  'AUXILIAR DE FARMACIA (TEMPORAL)': 'AUXILIAR DE FARMACIA (TEMPORAL)',
  'APRENDIZ DE FARMACIA TC': 'APRENDIZ DE FARMACIA TC',
  'ASISTENTE DE CONTROL DE PERDIDAS Y SERVICIO':
    'ASISTENTE DE CONTROL DE PERDIDAS Y SERVICIO',
  'ASISTENTE DE FARMACIA TC': 'ASISTENTE DE FARMACIA TC',
  'ASISTENTE DE OTC': 'ASISTENTE DE OTC',
  'ASISTENTE DE PISO DE VENTAS TC': 'ASISTENTE DE PISO DE VENTAS TC',
  'ASISTENTE DE PISO VENTA SENIOR': 'ASISTENTE DE PISO VENTA SENIOR',
  'ASISTENTE DE PISO DE VENTA TEMP': 'ASISTENTE DE PISO DE VENTA TEMP',
  'ASISTENTE PISO DE VENTAS F/S': 'ASISTENTE PISO DE VENTAS F/S',
  'FARMACEUTICO ADJUNTO': 'FARMACEUTICO ADJUNTO',
  'REGENTE DE FARMACIA': 'REGENTE DE FARMACIA',
  'REGENTE FARMACIA': 'REGENTE FARMACIA',
  'SUB-GERENTE DE TIENDA': 'SUB-GERENTE DE TIENDA',
  'FARMACEUTICO DE RECETURA': 'FARMACEUTICO DE RECETURA',
  'APRENDIZ INCES': 'APRENDIZ INCES',
  PASANTE: 'PASANTE',
  'DIRECTOR REGIONAL': 'DIRECTOR REGIONAL',
  'ASISTENTE DE PISO DE VENTAS 6 Hs': 'ASISTENTE DE PISO DE VENTAS 6 Hs',
  'APRENDIZ FARMACIA TC': 'APRENDIZ FARMACIA TC',
  'ASISTENTE DE PISO DE VENTA SENIOR': 'ASISTENTE DE PISO DE VENTA SENIOR',
  'GERENTE DE FLOTANTES': 'GERENTE DE FLOTANTES',
  'RECLUTAMIENTO Y SELECCION': 'RECLUTAMIENTO Y SELECCION',
  BIENESTAR: 'BIENESTAR',
  'GERENTE DE NOMINA': 'GERENTE DE NOMINA',
  'DIRECTOR DE OPERACIONES': 'DIRECTOR DE OPERACIONES',
  'COORDINADOR DE DESARROLLO': 'COORDINADOR DE DESARROLLO',
  'GERENTE DE DESARROLLO': 'GERENTE DE DESARROLLO',
  'ESPECIALISTA DE DESARROLLO': 'ESPECIALISTA DE DESARROLLO',
  'ANALISTA DE DESARROLLO': 'ANALISTA DE DESARROLLO',
}
