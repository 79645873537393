export const Environment = {
  development: 'development',
  production: 'production',
  staging: 'staging',
}

export const CONFIG_ENV_INITIAL = {
  development: {
    primaryColor: '#FF4B4B',
  },
  production: {
    primaryColor: '#2094fc',
  },
  staging: {
    primaryColor: '#2094fc',
  },
}

export const LinkToVersion = 'https://manual.corsox.com/changelog/'
