export const getValidRoutes = (routes, token, user, userPermissions) => {
  return routes
    .filter((route) => {
      const {
        protect = false,
        permission,
        customValidation = () => false,
        concatValidations = false,
      } = route.guardianConfig || {}

      const { routeValid } = isRouteValidWithPermissions(
        token,
        user,
        userPermissions,
        protect,
        permission
      )

      return concatConditions(concatValidations, routeValid, customValidation())
    })
    .map((route) => {
      return {
        ...route,
        children:
          route.children &&
          getValidRoutes(route.children, token, user, userPermissions),
      }
    })
}

/**
 *
 * @param {String} token from user
 * @param {Object} user
 * @param {Array<String> | String} roles from the route config
 * @param {boolean} protect from the route config
 * @returns {{validRole: boolean, validUser: boolean, routeValid: boolean}}
 */
export const isRouteValid = (token, user, roles, protect) => {
  const validUser = user && user._id && token
  const validRole = roles === '#' || roles.some((r) => r === user?.role || '')

  const routeValid = protect
    ? validUser && validRole
    : !validUser || (validUser && validRole)

  return { validUser, validRole, routeValid }
}

/**
 * It concats two conditions using && if isInclusive or ||
 * @param {boolean} isInclusive
 * @param {boolean} condition1
 * @param {boolean} condition2
 * @returns {boolean}
 */
export const concatConditions = (isInclusive, condition1, condition2) =>
  isInclusive ? condition1 && condition2 : condition1 || condition2

export const isRouteValidWithPermissions = (
  token,
  user,
  userPermissions,
  protect,
  permission
) => {
  const validUser = !!(user && user?._id && token)

  const arrayPermission = Array.isArray(permission)

  const validPermission =
    !permission || !arrayPermission
      ? userPermissions.findIndex((p) => p === permission) >= 0 ||
        permission === 0
      : userPermissions.some((p) => permission.includes(p))

  const routeValid = protect
    ? validUser && validPermission
    : !validUser || (validUser && validPermission)

  return { validUser, routeValid }
}
