import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useSelector } from 'react-redux'
import { App, ConfigProvider } from 'antd'
import timezone from 'dayjs/plugin/timezone'
import esLocale from 'antd/lib/locale/es_ES'
import { useIntl, IntlProvider } from 'react-intl'

import { selectSessionUser } from '../redux/session/selectors'
import { CONFIG_ENV_INITIAL } from '../util/constants/Environment'

import enLanguage from './languages/en.json'
import esLanguage from './languages/es.json'
import colLanguage from './languages/col.json'
import venLanguage from './languages/ven.json'
import argLanguage from './languages/arg.json'

import 'dayjs/locale/es-mx'

dayjs.locale('es-mx')

dayjs.extend(utc)
dayjs.extend(timezone)

const messages = {
  en: enLanguage,
  es: esLanguage,
  col: colLanguage,
  ven: venLanguage,
  arg: argLanguage,
}

const AntdProvider = ({ children }) => {
  const { formatMessage } = useIntl()

  const validateMessages = {
    required: formatMessage({ id: 'requiredField' }, { fieldName: '${label}' }),
    types: {
      email: formatMessage({ id: 'formatEmail' }, { fieldName: '${label}' }),
    },
  }

  return (
    <ConfigProvider
      locale={esLocale}
      form={{ validateMessages, requiredMark: true, scrollToFirstError: true }}
      input={{ autoComplete: 'off' }}
      theme={{
        token: {
          colorPrimary:
            CONFIG_ENV_INITIAL[process.env.REACT_APP_RUNTIME_ENV].primaryColor,
          fontFamily: 'Poppins',
          colorBgLayout: '#f0f2f5',
          colorTextDisabled: '#807b7b',
          colorTextPlaceholder: '#807b7b',
        },
      }}
    >
      <App message={{ maxCount: 1 }}>{children}</App>
    </ConfigProvider>
  )
}

const IntlWrapper = ({ children }) => {
  const sessionUser = useSelector(selectSessionUser)

  const currentMessages = messages[sessionUser?.country || 'es']

  return (
    <IntlProvider locale={'es'} messages={currentMessages}>
      <AntdProvider children={children} />
    </IntlProvider>
  )
}

export default IntlWrapper
