import produce from 'immer'
import {
  LOGIN,
  LOGOUT,
  NOT_FOUND,
  SET_PERMISSIONS,
  SET_PROGRESS_ON_LOADING,
  SET_USER,
  UPDATE_USER,
} from './constants'
import { IndexedPermissionsKeys } from '../../router/permissions/IndexedPermissionsKeys'

const initialState = {
  indexedPermissions: { [IndexedPermissionsKeys.DEFAULT]: [] },
}

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LOGIN:
        draft.user = action.user
        draft.token = action.token
        draft.indexedPermissions = action.indexedPermissions
        draft.sendesk_token = action.sendesk_token
        break
      case SET_USER:
        draft.user = action.user
        break
      case UPDATE_USER:
        draft.user = { ...draft.user, ...action.user }
        break
      case LOGOUT:
        return initialState
      case NOT_FOUND:
        draft.notFound = action.notFound
        break
      case SET_PERMISSIONS:
        draft.indexedPermissions = action.indexedPermissions
        break
      case SET_PROGRESS_ON_LOADING:
        draft.progressOnLoading = action.progress
        break

      default:
        break
    }
  })

export default reducer
