const reducerKey = 'session'
export const selectSessionUser = (state) => state[reducerKey].user
export const selectUserRole = (state) => state[reducerKey].user?.role
export const selectToken = (state) => state[reducerKey].token
export const selectSendeskToken = (state) => state[reducerKey].sendesk_token
export const selectProgressOnLoading = (state) =>
  state[reducerKey].progressOnLoading

export const selectNotFound = (state) => state[reducerKey].notFound

export const selectIndexedPermissions = (state) =>
  state[reducerKey].indexedPermissions || {}

export const selectUserPermissions = (state) => {
  const indexedPermissions = selectIndexedPermissions(state)
  return Object.values(indexedPermissions)?.flat()
}
