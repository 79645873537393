import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import AppRouter from './router/AppRouter'
import IntlWrapper from './intl/IntlWrapper'

import store, { persistor } from './redux/store'
import { getPathLoadCss } from './util/functions/loadCssFunctions'

import 'antd/dist/reset.css'
import './assets/css/index.css'

import(`./assets/css/${getPathLoadCss()}`)

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading='loading' persistor={persistor}>
        <IntlWrapper>
          <AppRouter />
        </IntlWrapper>
      </PersistGate>
    </Provider>
  )
}

export default App
