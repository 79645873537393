import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'
import {
  selectSessionUser,
  selectToken,
  selectUserPermissions,
} from '../redux/session/selectors'
import {
  concatConditions,
  getValidRoutes,
  isRouteValidWithPermissions,
} from '../util/functions/secureFunctions'
import { navRoutes, notFoundPermissionPath } from './routes'

const Guardian = ({
  children,
  protect,
  roles,
  permission,
  customValidation,
  concatValidations,
  redirectOnSession,
  pathToRedirect,
  escapeTo,
}) => {
  const token = useSelector(selectToken)
  const sessionUser = useSelector(selectSessionUser)
  const userPermissions = useSelector(selectUserPermissions)
  const navigate = useNavigate()

  const { validUser, routeValid } = isRouteValidWithPermissions(
    token,
    sessionUser,
    userPermissions,
    protect,
    permission
  )

  const validRoutes = getValidRoutes(
    navRoutes,
    token,
    sessionUser,
    userPermissions
  ).filter((route) => !route.hidden)

  const shouldRender = concatConditions(
    concatValidations,
    routeValid,
    /** Provide any data from user needed on routes **/
    customValidation()
  )

  useEffect(() => {
    if (redirectOnSession && validUser) {
      navigate(validRoutes[0]?.path ?? notFoundPermissionPath)
    }
    // eslint-disable-next-line
  }, [redirectOnSession, pathToRedirect, validUser, validRoutes])

  return shouldRender ? children : <Navigate to={escapeTo} />
}

Guardian.defaultProps = {
  protect: false,
  roles: '#',
  customValidation: () => false,
  concatValidations: false,
  redirectOnSession: false,
  pathToRedirect: '/',
}

export default Guardian
